import { Typography } from "@mui/material";
import Image from 'next/image';
import classes from './team.module.scss';
import { useTranslation } from "react-i18next";
import { Container } from "../container/container";
import clsx from "clsx";

export const Team = () => {
    const [ t ] = useTranslation();

    return <Container padded className={ classes.root } contentClassName={ classes.content }>
        <div className={ clsx(classes.entry, classes.description) }>
            <Typography id={'team'} variant={ 'h4' } component={ 'h3' } color={ 'primary' } className={ classes.title }>
                { t('pages.main.team') }
            </Typography>
            <Typography dangerouslySetInnerHTML={ { __html: t('pages.main.team_description') } }/>
        </div>

        <div className={ clsx(classes.entry, classes.profile) }>
            <Image className={ classes.avatar } src={ '/Justus.png' } width={ 200 } height={ 200 }/>
            <Typography variant={ 'body1' }
                className={ classes.profileName }>{ t('pages.main.team_member1_name') }</Typography>
            <Typography variant={ 'body1' }
                className={ classes.text }
                dangerouslySetInnerHTML={ { __html: t('pages.main.team_member1_description') } }/>
        </div>

        <div className={ clsx(classes.entry, classes.profile) }>
            <Image className={ classes.avatar } src={ '/Salome.png' } width={ 200 } height={ 200 }/>
            <Typography variant={ 'body1' }
                className={ classes.profileName }>{ t('pages.main.team_member2_name') }</Typography>
            <Typography variant={ 'body1' } className={ classes.text }
                dangerouslySetInnerHTML={ { __html: t('pages.main.team_member2_description') } }/>
        </div>
    </Container>
}
